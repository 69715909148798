import { css } from 'styled-components';
/* eslint-disable */

const buttonTheme = css<{ theme: string }>`
  color: ${(props) =>
    props.theme === 'white'
      ? '#016432'
      : props.theme === 'Back'
      ? '#000000'
      : '#ffffff'};
  background-color: ${(props) =>
    props.theme === 'white'
      ? '#ffffff'
      : props.theme === 'Back'
      ? 'transparent'
      : 'rgba(0, 50, 30, 1)'};
  border: ${(props) =>
    props.theme === 'white'
      ? '2px solid rgba(0, 98, 38, 0.4)'
      : props.theme === 'Back'
      ? 'unset'
      : 'unset'};
  width: ${(props) =>
    props.theme === 'white'
      ? '150px'
      : props.theme === 'full'
      ? '100%'
      : props.theme === 'CTA'
      ? '200px'
      : props.theme === 'Back'
      ? '70px'
      : '150px'};
  @media screen and (max-width: 768px) {
    width: 
    ${(props) => (props.theme === 'CTA' ? '200px' : '100px')}
    ${(props) => (props.theme === 'full' ? '100%' : null)}
    ${(props) => (props.theme === 'Back' ? '70px' : null)}
    ${(props) => (!props.theme ? '150px' : null)}
`;

const inputTheme = css<{ theme: string }>`
  display: ${(props) => (props.theme === 'file' ? 'none' : 'block')};
  width: ${(props) =>
    props.theme === 'light'
      ? '470px'
      : props.theme === 'full' || props.theme === 'file'
      ? '100%'
      : props.theme === 'grid'
      ? '100%'
      : props.theme === 'contact'
      ? '100%'
      : '47%'};
  border: ${(props) =>
    props.theme === 'light'
      ? '#D4E7ED 1px solid'
      : props.theme === 'file'
      ? 'unset'
      : 'unset'};
  @media screen and (max-width: 768px) {
    width: 280px;
  }
`;

export { buttonTheme, inputTheme };
