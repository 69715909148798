import MainContainer from 'components/MainContainer';
import { useEffect } from 'react';
import { ReactGA4Install } from 'utilities/reactGA4Event';

const App = () => {
  useEffect(() => {
    ReactGA4Install();
  });
  return <MainContainer />;
};

export default App;
