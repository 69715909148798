import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { AdvisorHybrid, OfficeHours } from 'models/advisor';
import { CoordinatePair } from 'models/coordinate';
import Icon from 'components/General/Icon';
import image from 'assets/images/placeholder.svg';
import phoneIcon from 'assets/images/phone.svg';
import markerIcon from 'assets/images/marker.svg';
import Button from 'components/General/Button';

import HaversineFormula from 'utilities/haversineFormula';
import ShuffleArray from 'utilities/arrayShuffle';
import { getDay } from 'date-fns';
import { ReactGA4Event } from 'utilities/reactGA4Event';

const ResultsScreen: React.FC<{
  filteredAdvisors: AdvisorHybrid[];
  userLocation?: CoordinatePair;
  handleAdvisorSelect: (advisorTeam: AdvisorHybrid) => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  mapboxSearchType: undefined | string;
  initialSearchPerformed: boolean;
}> = ({
  filteredAdvisors,
  userLocation,
  handleAdvisorSelect,
  setLoading,
  mapboxSearchType,
  initialSearchPerformed
}) => {
  const [resultSet, setResultSet] =
    React.useState<AdvisorHybrid[] | undefined>(undefined);
  const [paginationLimit, setPaginationLimit] = React.useState(20);
  const [viableAdvisorTotal, setViableAdvisorTotal] = React.useState(0);
  const [postScrambleResultSet, setPostScrambleResultSet] =
    React.useState<AdvisorHybrid[] | undefined>(undefined);

  const getField = (selectedAdvisor: AdvisorHybrid, field: string) => {
    if (
      selectedAdvisor.advisorFields.primaryOffice &&
      // @ts-expect-error
      selectedAdvisor.advisorFields.primaryOffice[`${field}`]
    ) {
      // @ts-expect-error
      return selectedAdvisor.advisorFields.primaryOffice[`${field}`];
    }
    // @ts-expect-error
    return selectedAdvisor.teamFields[`${field}`];
  };

  React.useEffect(() => {
    if (filteredAdvisors.length === 0) {
      setLoading(false);
    }
    const temporaryResultSet: AdvisorHybrid[] = [];
    let viableAdvisor = 0;
    if (filteredAdvisors && userLocation) {
      filteredAdvisors
        .sort((a, b) => {
          return (
            HaversineFormula(
              userLocation.latitude,
              userLocation.longitude,
              getField(a, 'lat'),
              getField(a, 'lon')
            ) -
            HaversineFormula(
              userLocation.latitude,
              userLocation.longitude,
              getField(b, 'lat'),
              getField(b, 'lon')
            )
          );
        })
        .map((advisorTeam) => {
          const verifyAdvisorPresent = temporaryResultSet.find(
            (i) =>
              i.advisorFields.locatorSlug ===
              advisorTeam.advisorFields.locatorSlug
          );
          if (
            verifyAdvisorPresent === undefined &&
            advisorTeam.advisorFields.enableAdvisorLocator
          ) {
            temporaryResultSet.push(advisorTeam);
            viableAdvisor += 1;
          }
        });
      setResultSet(filteredAdvisors);
    }
    setViableAdvisorTotal(viableAdvisor);
    setResultSet(temporaryResultSet);
  }, [filteredAdvisors]);

  React.useEffect(() => {
    if (viableAdvisorTotal > 0 && paginationLimit > viableAdvisorTotal) {
      setPaginationLimit(viableAdvisorTotal);
    }
    if (viableAdvisorTotal === 0) {
      setPaginationLimit(0);
    } else {
      if (viableAdvisorTotal > 20) {
        setPaginationLimit(20);
      } else {
        setPaginationLimit(viableAdvisorTotal);
      }
    }
  }, [postScrambleResultSet]);

  const getFriendlyHours = (officeHours: OfficeHours) => {
    const Today = getDay(new Date());

    if (Today === 1) {
      if (officeHours.Monday !== 'Closed') {
        return `Open today from ${officeHours.Monday}`;
      }
      return 'Closed today';
    }
    if (Today === 2) {
      if (officeHours.Tuesday !== 'Closed') {
        return `Open today from ${officeHours.Tuesday}`;
      }
      return 'Closed today';
    }
    if (Today === 3) {
      if (officeHours.Wednesday !== 'Closed') {
        return `Open today from ${officeHours.Wednesday}`;
      }
      return 'Closed today';
    }
    if (Today === 4) {
      if (officeHours.Thursday !== 'Closed') {
        return `Open today from ${officeHours.Thursday}`;
      }
      return 'Closed today';
    }
    if (Today === 5) {
      if (officeHours.Friday !== 'Closed') {
        return `Open today from ${officeHours.Friday}`;
      }
      return 'Closed today';
    }
    if (Today === 6) {
      if (officeHours.Saturday !== 'Closed') {
        return `Open today from ${officeHours.Saturday}`;
      }
      return 'Closed today';
    }
    if (Today === 0) {
      if (officeHours.Sunday !== 'Closed') {
        return `Open today from ${officeHours.Sunday}`;
      }
      return 'Closed today';
    } else return null;
  };

  React.useEffect(() => {
    if (resultSet && userLocation && mapboxSearchType === 'city') {
      let indexToScrambleAt = 1;
      resultSet.forEach((teamMember) => {
        if (
          HaversineFormula(
            userLocation.latitude,
            userLocation.longitude,
            getField(teamMember, 'lat'),
            getField(teamMember, 'lon')
          ) <= 25
        ) {
          indexToScrambleAt += 1;
        }
      });
      const prescramble = resultSet.slice(0, indexToScrambleAt);
      const remainingItems = resultSet.slice(
        indexToScrambleAt,
        resultSet.length
      );

      const finalArray: AdvisorHybrid[] = [];
      const scrambled = ShuffleArray(prescramble);
      scrambled.forEach((item) => {
        finalArray.push(item);
      });
      remainingItems.forEach((unmodifiedItem) => {
        finalArray.push(unmodifiedItem);
      });
      setPostScrambleResultSet(finalArray);
      setLoading(false);
    } else {
      setPostScrambleResultSet(resultSet);
      setLoading(false);
    }
  }, [resultSet]);

  const initReactGA4Event = (name: string, email?: string) => {
    ReactGA4Event('event', 'internal_click', {
      link_category: 'find_an_advisor',
      advisor: `${name} -- ${email}`,
      link_id: 'advisorResultCard',
      link_text: 'advisorResultCard',
      link_url: 'advisorResultCard'
    });
  };

  const getResultsText = () =>
    viableAdvisorTotal === 0 ? (
      <Text>{'Showing 0 results'}</Text>
    ) : (
      <Text>{`Showing ${paginationLimit} of ${viableAdvisorTotal} results`}</Text>
    );

  return (
    <Container>
      <SortedShim>
        {initialSearchPerformed ? getResultsText() : null}
      </SortedShim>

      {postScrambleResultSet && userLocation
        ? postScrambleResultSet.map((advisorTeam, index) => {
            if (index < paginationLimit)
              return (
                <ResultCard
                  id="advisorResultCard"
                  aria-label={`This button opens more information about ${getField(
                    advisorTeam,
                    'name'
                  )}`}
                  key={`${index}- advisorTeam.name`}
                  onClick={() => {
                    handleAdvisorSelect(advisorTeam);
                    initReactGA4Event(
                      advisorTeam.advisorFields.name,
                      advisorTeam.advisorFields.email
                    );
                  }}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === 13) {
                      initReactGA4Event(
                        advisorTeam.advisorFields.name,
                        advisorTeam.advisorFields.email
                      );
                    }
                  }}
                >
                  <DetailsColumn>
                    <Photo
                      alt={`${advisorTeam.advisorFields.name}'s photograph`}
                      src={advisorTeam.advisorFields.photo || image}
                    />
                    <AdvisorDetails>
                      <AdvisorName>
                        {advisorTeam.advisorFields.name}
                      </AdvisorName>
                      <Address>
                        {getField(advisorTeam, 'address')}
                        <br />
                        {` ${getField(advisorTeam, 'city')} ${getField(
                          advisorTeam,
                          'province'
                        )}, ${getField(advisorTeam, 'postalCode')}`}
                      </Address>
                    </AdvisorDetails>
                  </DetailsColumn>
                  <LocationColumn>
                    <LocationDetails>
                      <Icon
                        image={markerIcon}
                        alt=""
                        text={`${Math.round(
                          HaversineFormula(
                            userLocation.latitude,
                            userLocation.longitude,
                            getField(advisorTeam, 'lat'),
                            getField(advisorTeam, 'lon')
                          )
                        )} km`}
                      />
                      <Icon
                        alt=""
                        image={phoneIcon}
                        text={getField(advisorTeam, 'phone')}
                      />
                      <Hours>
                        {!advisorTeam?.advisorFields.primaryOffice
                          ?.officeHours &&
                        advisorTeam?.teamFields?.officeHours &&
                        getFriendlyHours(
                          advisorTeam?.teamFields?.officeHours
                        ) !== undefined
                          ? getFriendlyHours(
                              advisorTeam?.teamFields?.officeHours
                            )
                          : null}

                        {advisorTeam?.advisorFields.primaryOffice
                          ?.officeHours &&
                        getFriendlyHours(
                          advisorTeam?.advisorFields.primaryOffice?.officeHours
                        ) !== undefined
                          ? getFriendlyHours(
                              advisorTeam?.advisorFields.primaryOffice
                                ?.officeHours
                            )
                          : null}
                      </Hours>
                    </LocationDetails>
                  </LocationColumn>
                </ResultCard>
              );
          })
        : null}
      {filteredAdvisors.length > 0 && paginationLimit < viableAdvisorTotal ? (
        <PaginationShim>
          <Button
            handleClick={() => {
              if (paginationLimit + 20 >= viableAdvisorTotal) {
                setPaginationLimit(viableAdvisorTotal);
              } else {
                setPaginationLimit(paginationLimit + 20);
              }
            }}
            aria-label="Load more advisors"
            type="button"
            text="Load More"
          />
        </PaginationShim>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 60px;
`;

const SortedShim = styled.div`
  width: 55%;
  font-family: 'Roboto Flex', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0.01em;
  text-align: left;
  margin: 0 auto;
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding-bottom: 20px;
  @media screen and (max-width: 767px) {
    width: 90%;
    font-size: 16px;
    flex-direction: column;
    text-align: center;
  }
`;

const Text = styled.p`
  margin-top: 0px;
  color: #000000;
  font-weight: 500;
  font-family: 'Roboto Flex', sans-serif;
`;

const ResultCard = styled.button`
  width: 55%;
  height: auto;
  padding: 0px;
  background-color: #ffffff;
  display: flex;
  border: 1px solid #d4e7ed;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  font-family: 'Roboto Flex', sans-serif;
  box-shadow: 0px 4px 12px rgba(37, 47, 35, 0.15);
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: 2px dotted #000000;
  }
  @media screen and (max-width: 767px) {
    width: 90%;
    flex-direction: column;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    width: 90%;
    font-size: 18px;
  }
`;

const Hours = styled.p`
  font-family: 'Roboto Flex', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(0, 103, 38, 1);
  text-align: left;
  margin: 0px;
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`;

const DetailsColumn = styled.div`
  display: flex;
  width: 55%;
  padding: 20px 20px;
  @media screen and (max-width: 767px) {
    width: 90%;
    padding: 15px 0px;
  }
`;

const AdvisorName = styled.h3`
  color: rgba(0, 50, 30, 1);
  font-family: 'Roboto Flex', sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 0.01em;
  text-align: left;
  margin: 0px 0px 3px 0px;
  padding-bottom: 5px;
  @media screen and (max-width: 767px) {
    font-size: 20px;
  }
`;

const AdvisorDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 20px;
  @media screen and (max-width: 767px) {
    justify-content: unset;
  }
`;

const LocationDetails = styled.div`
  padding: 0px 20px;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  height: 95%;
  justify-content: space-evenly;
  @media screen and (max-width: 767px) {
    padding: 0px 12px;
  }
`;

const Address = styled.p`
  color: rgba(0, 0, 0, 1);
  font-family: 'Roboto Flex', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0.02em;
  margin: 0px;
  text-align: left;
  @media screen and (max-width: 767px) {
    line-height: 22px;
  }
`;

const Photo = styled.img`
  width: 120px;
  max-height: 120px;
  @media screen and (max-width: 767px) {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
`;

const LocationColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  padding: 22px 0px;
  align-items: flex-start;
  height: 100%;
  background-color: #f5f8f9;
  border-right: 10px solid rgba(0, 50, 30, 1);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  @media screen and (max-width: 767px) {
    width: 100%;
    min-height: unset;
    padding: 0px;
  }
`;

const PaginationShim = styled.div``;

export default ResultsScreen;
