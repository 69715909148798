import React, { useState } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';

import { AdvisorFields, TeamFields } from 'models/advisor';
import collectSpecificAdvisorTeam from 'queries/collectSpecificAdvisorTeam';
import { advisorType } from 'utilities/CheckboxLists';
import * as yup from 'yup';

import Input from 'components/General/Input';
import Button from 'components/General/Button';
import Icon from 'components/General/Icon';
import image from 'assets/images/placeholder.svg';
import RadioCircle from 'assets/images/radio.svg';
import Checkmark from 'assets/images/checkmark.svg';
import ClipLoader from 'react-spinners/ClipLoader';
/* eslint-disable-next-line*/
const axios = require('axios');

const schema = yup.object().shape({
  name: yup.string(),
  title: yup.string(),
  email: yup.string().email(),
  linkedIn: yup.string().url(),
  facebook: yup.string().url(),
  bookAnAppointment: yup.string().url(),
  expertise: yup.array().of(yup.string()),
  description: yup.string(),
  comments: yup.string()
});

const EditTeamMemberScreen: React.FC = () => {
  const urlVariables = queryString.parse(window.location.search);
  const [advisorTeamSlug] = React.useState<string | string[] | null>(
    urlVariables.advisorTeamSlug
  );
  const [teamMemberSlug] = React.useState<string | string[] | null>(
    urlVariables.teamMemberSlug
  );
  const [loading, setLoading] = React.useState(false);

  const [name, setName] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [linkedIn, setLinkedIn] = React.useState('');
  const [facebook, setFacebook] = React.useState('');
  const [bookAnAppointment, setBookAnAppointment] = React.useState('');
  const [photo, setPhoto] = React.useState<any>('');
  const [typeOfAdvisor, setTypeOfAdvisor] = useState<string>('');
  const [adviceYourWayAdvisor, setAdviceYourWayAdvisor] =
    useState<boolean>(false);
  const [comments, setComments] = React.useState<string | undefined>('');
  const [expertise, setExpertise] = React.useState<string[] | undefined>([]);
  const [friendlyExpertise, setFriendlyExpertise] = React.useState('');
  const [writeableExpertise, setWriteableExpertise] = React.useState<
    string[] | undefined
  >([]);
  const [description, setDescription] = React.useState<string>('');
  const [id, setId] = useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const [advisorTeamName, setAdvisorTeamName] =
    React.useState<string | undefined>('');
  const [advisorTeamAddress1, setAdvisorTeamAddress1] =
    React.useState<string | undefined>('');
  const [advisorTeamAddress2, setAdvisorTeamAddress2] =
    React.useState<string | undefined>('');
  const [advisorTeamCity, setAdvisorTeamCity] =
    React.useState<string | undefined>('');
  const [advisorTeamProvince, setAdvisorTeamProvince] =
    React.useState<string | undefined>('');
  const [advisorTeamPostalCode, setAdvisorTeamPostalCode] =
    React.useState<string | undefined>('');
  const [advisorTeamMembers, setAdvisorTeamMembers] = React.useState<
    AdvisorFields[] | undefined
  >([]);
  const [friendlyAdvisorTeamMembers, setFriendlyAdvisorTeamMembers] =
    React.useState('');
  const [advisorTeamWebsite, setAdvisorTeamWebsite] =
    React.useState<string | undefined>('');
  const [advisorTeamPhone, setAdvisorTeamPhone] =
    React.useState<string | undefined>('');

  const [modifiedTeamMemberObject, setModifiedTeamMemberObject] =
    React.useState<AdvisorFields>({
      name,
      title: '',
      email,
      linkedIn,
      facebook,
      bookAnAppointment,
      photo,
      typeOfAdvisor,
      adviceYourWayAdvisor,
      id,
      description,
      locatorSlug: '',
      comments,
      expertise
    });

  const [previousRecordCopy, setPreviousRecordCopy] =
    React.useState<AdvisorFields>({
      name,
      title: '',
      email,
      linkedIn,
      facebook,
      bookAnAppointment,
      photo,
      typeOfAdvisor,
      adviceYourWayAdvisor,
      id,
      description,
      locatorSlug: '',
      comments,
      expertise
    });

  React.useEffect(() => {
    collectFields();
    // eslint-disable-next-line
  }, []);

  const collectFields = async () => {
    if (
      advisorTeamSlug &&
      typeof advisorTeamSlug === 'string' &&
      teamMemberSlug
    ) {
      await collectSpecificAdvisorTeam(advisorTeamSlug).then(
        (contentfulObject) => {
          const advisorTeam: TeamFields = contentfulObject[0];

          if (advisorTeam && teamMemberSlug) {
            setAdvisorTeamName(advisorTeam.name);
            setAdvisorTeamAddress1(advisorTeam.address);
            setAdvisorTeamAddress2(advisorTeam.address2);
            setAdvisorTeamCity(advisorTeam.city);
            setAdvisorTeamProvince(advisorTeam.province);
            setAdvisorTeamPostalCode(advisorTeam.postalCode);
            setAdvisorTeamMembers(advisorTeam.advisorTeamInResultSet);
            setAdvisorTeamWebsite(advisorTeam.website);
            setAdvisorTeamPhone(advisorTeam.phone);
            advisorTeam.advisorTeamInResultSet?.forEach((advisor) => {
              if (advisor.locatorSlug === teamMemberSlug) {
                setName(advisor.name);
                setTitle(advisor.title);
                setEmail(advisor.email ?? '');
                setLinkedIn(advisor.linkedIn ?? '');
                setFacebook(advisor.facebook ?? '');
                setBookAnAppointment(advisor.bookAnAppointment ?? '');
                setPhoto(advisor.photo ?? '');
                setTypeOfAdvisor(advisor.typeOfAdvisor ?? '');
                setAdviceYourWayAdvisor(advisor.adviceYourWayAdvisor ?? false);
                setId(advisor.id);
                setComments(advisor.comments ?? '');
                setExpertise(advisor.expertise ?? []);
                setDescription(advisor.description ?? '');
                setPreviousRecordCopy({
                  name: advisor.name || '',
                  title: advisor.title || '',
                  email: advisor.email || '',
                  linkedIn: advisor.linkedIn || '',
                  facebook: advisor.facebook || '',
                  bookAnAppointment: advisor.bookAnAppointment || '',
                  photo: advisor.photo,
                  typeOfAdvisor: advisor.typeOfAdvisor || '',
                  adviceYourWayAdvisor: advisor.adviceYourWayAdvisor,
                  id: advisor.id,
                  locatorSlug: '',
                  comments: advisor.comments || '',
                  expertise: advisor.expertise || [],
                  description: advisor.description || ''
                });
              }
            });
          }
        }
      );
    }
  };

  React.useEffect(() => {
    let sentence = '';
    advisorTeamMembers?.forEach((advisor: AdvisorFields) => {
      sentence += `${advisor.name}, `;
    });

    setFriendlyAdvisorTeamMembers(sentence.slice(0, -2));
  }, [advisorTeamMembers]);

  React.useEffect(() => {
    let sentence = '';
    expertise?.forEach((expertise: string) => {
      sentence += `${expertise}, `;
    });

    setFriendlyExpertise(sentence.slice(0, -2));
  }, [expertise]);

  React.useEffect(() => {
    const untidy = friendlyExpertise.split(',');
    const cleaned: string[] = [];
    untidy.forEach((string) => {
      cleaned.push(string.trim());
    });
    setWriteableExpertise(cleaned);
  }, [friendlyExpertise]);

  React.useEffect(() => {
    validateFormData();
  }, [modifiedTeamMemberObject]);

  const validateFormData = async () => {
    try {
      await schema.validate({
        name,
        title,
        email,
        linkedIn,
        facebook,
        bookAnAppointment,
        expertise,
        description,
        comments
      });
      setErrorMessage('');
    } catch (err: any) {
      setErrorMessage(err);
    }
  };

  const submitChanges = async () => {
    if (advisorTeamName) {
      try {
        setLoading(true);
        try {
          const result = await axios({
            method: 'post',
            url: 'https://eszu55dnuf.execute-api.us-east-1.amazonaws.com/stage/write',
            //url: 'https://eszu55dnuf.execute-api.us-east-1.amazonaws.com/stage/writeStage',
            data: {
              modifiedTeamMemberObject,
              advisorTeamName,
              linkToView: `${process.env.REACT_APP_ROOT_URL}?advisorTeamSlug=${advisorTeamSlug}&teamMemberSlug=${teamMemberSlug}`
            }
          });
          if (result.status === 200) {
            alert(
              'Your entry has been submitted successfully, and a record of your approval has been sent to an IPC Administrator. Thank you for your time!'
            );
          }
        } catch (e) {
          alert(
            'Something went wrong, please screenshot your webpage and contact your administrator'
          );
        }

        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    setModifiedTeamMemberObject({
      name,
      title,
      email,
      linkedIn,
      facebook,
      bookAnAppointment,
      photo,
      typeOfAdvisor,
      adviceYourWayAdvisor,
      id,
      locatorSlug: '',
      comments,
      expertise: writeableExpertise,
      description
    });
  }, [
    name,
    title,
    email,
    linkedIn,
    facebook,
    bookAnAppointment,
    photo,
    typeOfAdvisor,
    adviceYourWayAdvisor,
    id,
    comments,
    writeableExpertise,
    description
  ]);

  // React.useEffect(() => {
  //   const fileInput = document.getElementById('photo');
  //   if (fileInput) {
  //     fileInput.addEventListener('change', (e) => {
  //       //@ts-expect-error here
  //       setPhoto(URL.createObjectURL(e.target.files[0]));
  //       console.log(event);
  //       const reader = new FileReader();

  //       reader.onload = function () {
  //         setPhoto(reader.result);
  //       };
  //       reader.readAsDataURL(input.files[0]);
  //     });
  //   }
  // }, []);

  return (
    <Container>
      <Title>{`Welcome, ${previousRecordCopy.name}`}</Title>
      <Subtext>
        {`Congratulations for being included as part of IPC’s new Advisor Locator
        Tool. Please take a few minute to review to ensure all your information
        is accurate. You can make your edits directly on this form and hit
        Approve & Submit once complete. If no changes are required, please hit
        Approve & Submit to complete the process.`}
      </Subtext>
      <SectionHeader>Your information</SectionHeader>
      <Form>
        <FormSection>
          <PhotoContainer>
            <Photo src={photo ?? image} alt="" />
            <PhotoDetails>
              <p>Your Image</p>
              {/* <PhotoLabel
                onKeyDown={(e) => console.log('Simulate click on Input', e)}
                tabIndex={0}
                htmlFor="photo"
              >
                Choose File */}
              {/* <Input
                  theme="file"
                  type="file"
                  name="photo"
                  id="photo"
                  value={undefined}
                  accept={'image/*'}
                  onChange={(e) => {
                    openFile(e);
                  }}
                /> */}
              {/* </PhotoLabel> */}

              <Tooltip>
                If you would like your photo modified, please submit a note at
                the bottom of this form and your administrator will contact you
              </Tooltip>
            </PhotoDetails>
          </PhotoContainer>
          <Label htmlFor="name">
            Your name
            <Input
              theme="grid"
              name="name"
              id="name"
              value={name}
              type="text"
              placeholder="Name"
              onChange={setName}
            />
          </Label>
          <RadioBoxesFields>
            <Legend>Type of Advisor</Legend>
            {advisorType.map((item) => {
              return (
                <RadioWrapper key={item.id}>
                  <Radio
                    name={item.name}
                    id={item.id}
                    type="radio"
                    checked={typeOfAdvisor === item.id}
                    onChange={() => {
                      setTypeOfAdvisor(item.id);
                    }}
                  />

                  <RadioLabel
                    onChange={() => {
                      setTypeOfAdvisor(item.id);
                    }}
                    onClick={() => {
                      setTypeOfAdvisor(item.id);
                    }}
                    htmlFor={item.id}
                  >
                    {item.label}
                  </RadioLabel>
                </RadioWrapper>
              );
            })}
          </RadioBoxesFields>
          <CheckMarkFields>
            <Legend>Are you an Advice Your Way advisor?</Legend>
            <CheckboxLabel
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  setAdviceYourWayAdvisor(!adviceYourWayAdvisor);
                }
              }}
              onChange={() => {
                setAdviceYourWayAdvisor(!adviceYourWayAdvisor);
              }}
              htmlFor="consult"
            >
              Yes
              <HiddenCheckbox
                aria-hidden
                tabIndex={-1}
                onChange={() => {
                  setAdviceYourWayAdvisor(!adviceYourWayAdvisor);
                }}
                checked={adviceYourWayAdvisor}
                id="consult"
                name="consult"
                type="checkbox"
              />
              <CheckBox>
                <Check aria-hidden checked={adviceYourWayAdvisor}>
                  <Icon image={Checkmark} alt="" />
                </Check>
              </CheckBox>
            </CheckboxLabel>
          </CheckMarkFields>
          <Label htmlFor="title">
            Your Title
            <Input
              theme="grid"
              name="title"
              id="title"
              value={title}
              type="text"
              placeholder="Title"
              onChange={setTitle}
            />
          </Label>
          <Label htmlFor="email">
            Your Email (used to direct contact form to email)
            <Input
              theme="grid"
              name="email"
              id="email"
              value={email}
              type="text"
              placeholder="Email"
              onChange={setEmail}
            />
          </Label>
          <Label htmlFor="linkedIn">
            Your LinkedIn Link
            <Input
              theme="grid"
              name="linkedIn"
              id="linkedIn"
              value={linkedIn}
              type="text"
              placeholder="LinkedIn"
              onChange={setLinkedIn}
            />
          </Label>
          <Label htmlFor="facebook">
            Your Facebook Link
            <Input
              theme="grid"
              type="text"
              name="facebook"
              id="facebook"
              value={facebook}
              placeholder="Facebook"
              onChange={setFacebook}
            />
          </Label>
          <Label htmlFor="appointment">
            Book an appointment link (optional) eg. if you have a calend.ly link{' '}
            <Input
              theme="grid"
              type="text"
              name="appointment"
              id="appointment"
              value={bookAnAppointment}
              placeholder="Calend.ly link"
              onChange={setBookAnAppointment}
            />
          </Label>
        </FormSection>
        <Label htmlFor="">
          Your Expertise
          <Input
            theme="grid"
            type="text"
            name=""
            id=""
            value={friendlyExpertise}
            placeholder="Expertise 1, Expertise 2, Expertise 3, Expertise 4"
            onChange={setFriendlyExpertise}
          />
        </Label>
        <Label style={{ marginTop: '5px' }}>
          Your Bio
          <TextArea
            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, et mattis vivamus bibendum et libero mattis. Gravida lacus morbi aliquam dui. Duis nunc, massa varius justo."
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Label>
        <SectionHeader>Your office</SectionHeader>
        <FormSection>
          <FullLabel htmlFor="advisorOffice">
            Advisor Office Name (internal use only)
            <Input
              type="text"
              name="advisorOffice"
              id="advisorOffice"
              value={advisorTeamName}
              disabled
            />
          </FullLabel>
          <Label htmlFor="advisorAddress1">
            Address 1
            <Input
              theme="grid"
              type="text"
              name="advisorAddress1"
              id="advisorAddress1"
              value={advisorTeamAddress1}
              disabled
            />
          </Label>
          <Label htmlFor="advisorAddress2">
            Address 2
            <Input
              theme="grid"
              type="text"
              name="advisorAddress2"
              id="advisorAddress2"
              value={advisorTeamAddress2 || undefined}
              disabled
            />
          </Label>
          <Label htmlFor="city">
            City
            <Input
              theme="grid"
              type="text"
              name="city"
              id="city"
              value={advisorTeamCity}
              disabled
            />
          </Label>
          <Label htmlFor="province">
            Province
            <Input
              theme="grid"
              type="text"
              name="province"
              id="province"
              value={advisorTeamProvince}
              disabled
            />
          </Label>
          <Label htmlFor="postal">
            Postal Code
            <Input
              theme="grid"
              type="text"
              name="postal"
              id="postal"
              value={advisorTeamPostalCode}
              disabled
            />
          </Label>
          <FullLabel htmlFor="teamMembers">
            Team members
            <Input
              theme="full"
              type="text"
              name="teamMembers"
              id="teamMembers"
              value={friendlyAdvisorTeamMembers}
              disabled
            />
          </FullLabel>
          <Label htmlFor="website">
            Website
            <Input
              theme="grid"
              type="text"
              name="website"
              id="website"
              value={advisorTeamWebsite}
              disabled
            />
          </Label>
          <Label htmlFor="phone">
            Phone
            <Input
              theme="grid"
              type="phone"
              name="phone"
              id="phone"
              value={advisorTeamPhone}
              disabled
            />
          </Label>
        </FormSection>
        <Divider />
        <Label>
          Comments or Change Requests
          <TextArea
            placeholder="Your comments here..."
            value={comments}
            onChange={(e) => {
              setComments(e.target.value);
            }}
          />
        </Label>
        {errorMessage ? <ErrorLabel>{`${errorMessage}`}</ErrorLabel> : null}
        {loading ? (
          <LoadingContainer>
            <ClipLoader color={'#FFFFFF'} loading={loading} size={25} />
          </LoadingContainer>
        ) : (
          <Button
            disabled={errorMessage ? true : false}
            type="button"
            text={'Approve & Submit'}
            handleClick={() => {
              submitChanges();
            }}
          />
        )}
      </Form>
    </Container>
  );
};

const LoadingContainer = styled.div`
  width: 148px;
  height: 54px;
  background-color: #016432;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorLabel = styled.div`
  color: red;
  margin-bottom: 20px;
`;

const Container = styled.div`
  width: 55%;
  min-width: 800px;
  box-sizing: border-box;
  padding: 50 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 auto;
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  align-items: center;
  justify-content: center;
  font-size: 44px;
  margin-top: 70px;
  text-align: center;
`;

const Subtext = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-top: 50px;
`;

const SectionHeader = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  align-items: center;
  justify-content: flex-start;
  font-size: 32px;
  margin-top: 50px;
  padding-bottom: 30px;
`;

const Divider = styled.hr`
  background-color: #f5f8f9;
  margin: 20px 0px;
  border: unset;
  height: 3px;
  width: 100%;
`;

const Form = styled.div`
  padding-bottom: 70px;
`;

const FormSection = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 70px);
  grid-gap: 25px 0px;
  margin-bottom: 5px;
`;

const TextArea = styled.textarea`
  resize: none;
  height: 200px;
  width: 100%;
  margin-top: 3px;
  margin-bottom: 30px;
  font-family: 'Roboto Flex', sans-serif;
  font-size: 16px;
  line-height: 28px;
  text-indent: 5px;
  background-color: #f5f8f9;
  border: unset;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 10px;
  box-sizing: border-box;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */

    font-family: 'Roboto Flex', sans-serif;
    padding-left: 5px;
    font-size: 18px;
    color: #000000;
    opacity: 0.4;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-family: 'Roboto Flex', sans-serif;
    padding-left: 5px;
    font-size: 18px;
    color: #000000;
    opacity: 0.4;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    font-family: 'Roboto Flex', sans-serif;
    padding-left: 5px;
    font-size: 18px;
    color: #000000;
    opacity: 0.4;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-family: 'Roboto Flex', sans-serif;
    padding-left: 5px;
    font-size: 18px;
    color: #000000;
    opacity: 0.4;
  }
  &:focus {
    outline: 2px dotted #000000;
  }
`;

const PhotoContainer = styled.div`
  display: flex;
  grid-row: 1 / span 3;
`;

const Photo = styled.img`
  max-width: 230px;
  height: auto;
`;

const Tooltip = styled.p``;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  min-height: 78px;
  justify-content: space-evenly;
  width: 95%;
`;

const FullLabel = styled.label`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  min-height: 78px;
  justify-content: space-evenly;
`;

const PhotoDetails = styled.div`
  box-sizing: border-box;
  padding: 15px;
  padding-top: 0px;
`;

// const PhotoLabel = styled.label`
//   border: 1px solid #ccc;
//   display: inline-block;
//   padding: 6px 12px;
//   cursor: pointer;
//   background: #f3f3f3;
//   border: 1px solid #d9d9d9;
//   :focus {
//     outline: 2px dotted #000000;
//   }
// `;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  min-height: fit-content;
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  font-family: 'Roboto Flex', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const RadioLabel = styled.label`
  font-family: 'Roboto Flex', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: -40px;

    /* Fixed alignment in some browsers. */
    top: 0;

    border-radius: 50%;
    background: #f5f8f9;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 22px;
    height: 22px;
  }

  &::after {
    content: '';
    position: absolute;
    left: -36px;
    top: 4px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
  }
  &:focus {
    outline: 2px dotted #000000;
  }
`;

const RadioBoxesFields = styled.fieldset`
  display: flex;
  border: unset;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
`;

const CheckBox = styled.span`
  position: absolute;
  top: 5px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #f5f8f9;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const CheckMarkFields = styled.fieldset`
  min-height: 50px;
  border: unset;
  display: flex;
  padding: 0;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-family: 'Roboto Flex', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 35px;
  line-height: 115%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:focus {
    outline: 2px dotted #000000;
  }
`;

const Legend = styled.legend`
  font-family: 'Roboto Flex', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding: 0;
`;

const HiddenCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Radio = styled.input<{ checked: boolean }>`
  opacity: 0;
  height: 0;
  width: 0;
  &:checked {
    + ${RadioLabel}::after {
      width: 16px;
      content: url(${RadioCircle});
    }
  }
  &:focus {
    + ${RadioLabel}::before {
      outline: 2px dotted #000000;
    }
`;

const Check = styled.span<{ checked: boolean }>`
  position: absolute;
  width: 16px;
  top: 5px;
  left: 5px;
  visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
`;

export default EditTeamMemberScreen;
