import React, { useState, Dispatch, SetStateAction, useRef } from 'react';
import styled from 'styled-components';

import AriaModal from 'react-aria-modal';
import Button from 'components/General/Button';
import InputValidated from 'components/General/InputValidated';
import Icon from 'components/General/Icon';
import { sourceBoxes, interestBoxes } from 'utilities/CheckboxLists';
import Close from 'assets/images/close.svg';
import Checkmark from 'assets/images/checkmark.svg';
import ClipLoader from 'react-spinners/ClipLoader';

import { InputFields } from 'models/input';
import { ReactGA4Event } from 'utilities/reactGA4Event';

/* eslint-disable-next-line*/
const axios = require('axios');

const ContactModal: React.FC<{
  modalActive: boolean;
  setModalActive: Dispatch<SetStateAction<boolean>>;
  teamName: string;
  advisorRecipient: string | undefined;
}> = ({ modalActive, setModalActive, teamName, advisorRecipient }) => {
  const [checked, setChecked] = useState<boolean>(false);
  const [proximityCheck, setProximityCheck] = React.useState(false);
  const [referer, setReferer] = React.useState<string[]>([]);
  const [financialInterests, setFinancialInterests] = React.useState<string[]>(
    []
  );
  const [comment, setComment] = React.useState('');
  const [friendlyReferrer, setFriendlyReferrer] = React.useState('');
  const [friendlyFinancialInterests, setFriendlyFinancialInterests] =
    React.useState('');
  const [friendlyChecked, setFriendlyChecked] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [validated, setValidated] = useState<boolean>();
  const [formSuccess, setFormSuccess] = useState<boolean>();
  const myRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [firstName, setFirstName] = useState<InputFields>({
    value: '',
    error: true,
    touched: false
  });

  const [lastName, setLastName] = useState<InputFields>({
    value: '',
    error: true,
    touched: false
  });

  const [phone, setPhone] = useState<InputFields>({
    value: '',
    error: true,
    touched: false
  });

  const [email, setEmail] = useState<InputFields>({
    value: '',
    error: true,
    touched: false
  });

  const [province, setProvince] = useState<InputFields>({
    value: '',
    error: true,
    touched: false
  });

  const [city, setCity] = useState<InputFields>({
    value: '',
    error: true,
    touched: false
  });

  React.useEffect(() => {
    let sentence = '';
    referer.forEach((refererType) => {
      sentence += `${refererType}, `;
    });
    setFriendlyReferrer(sentence.slice(0, -2));
  }, [referer]);

  React.useEffect(() => {
    let sentence = '';
    financialInterests.forEach((interest) => {
      sentence += `${interest}, `;
    });
    setFriendlyFinancialInterests(sentence.slice(0, -2));
  }, [financialInterests]);

  React.useEffect(() => {
    if (checked) {
      setFriendlyChecked('yes');
    } else {
      setFriendlyChecked('no');
    }
  }, [checked]);

  const getBoolean = (checkedGroup: string, substring: string) =>
    `${checkedGroup.includes(substring) ? 'true' : 'false'}`;

  const submitForm = async () => {
    if (
      !firstName.error &&
      !lastName.error &&
      !phone.error &&
      !email.error &&
      !province.error &&
      !city.error &&
      referer &&
      financialInterests
    ) {
      setValidated(true);
      setLoading(true);
      ReactGA4Event('event', 'form_submit', {
        form_id: 'find_an_advisor',
        advisor: `${teamName} -- ${advisorRecipient}`,
        free_consultation: friendlyChecked,
        referred_friend: getBoolean(friendlyReferrer, 'referred'),
        referred_google: getBoolean(friendlyReferrer, 'google'),
        referred_social: getBoolean(friendlyReferrer, 'event'),
        referred_event: getBoolean(friendlyReferrer, 'social'),
        referred_other: getBoolean(friendlyReferrer, 'sourceOther'),
        portfolio_review: getBoolean(friendlyFinancialInterests, 'portfolio'),
        retirement_planning: getBoolean(
          friendlyFinancialInterests,
          'retirementPlanning'
        ),
        mortgage_free: getBoolean(friendlyFinancialInterests, 'mortgageFree'),
        estate_planning: getBoolean(
          friendlyFinancialInterests,
          'estatePlanning'
        ),
        child_education: getBoolean(
          friendlyFinancialInterests,
          'childEducation'
        ),
        tax_planning: getBoolean(friendlyFinancialInterests, 'taxPlanning'),
        other: getBoolean(friendlyFinancialInterests, 'interestOther'),
        comments: comment
      });

      try {
        if (advisorRecipient) {
          const generateAxiosObject = (recipient: string) => {
            return {
              to: [
                {
                  email: recipient,
                  name: teamName
                }
              ],
              from: {
                email: 'ServiceUser@Innovasium.com',
                name: 'Innovasium Service User - Advisorfinder'
              },
              templateId: 'd-399473655bca4395a6a53fa135590b18',
              dynamicTemplateData: {
                advisorName: teamName,
                firstName: firstName.value,
                lastName: lastName.value,
                phone: phone.value,
                city: city.value,
                email: email.value,
                province: province.value,
                proximityCheck,
                referrer: friendlyReferrer,
                financialInterests: friendlyFinancialInterests,
                consultBool: friendlyChecked,
                comment
              }
            };
          };
          await axios({
            method: 'post',
            url: 'https://b2svh8ioo3.execute-api.us-east-1.amazonaws.com/production/ipc-advisor',
            data: generateAxiosObject(advisorRecipient)
          });
          await axios({
            method: 'post',
            url: 'https://b2svh8ioo3.execute-api.us-east-1.amazonaws.com/production/ipc-advisor',
            data: generateAxiosObject('webadmin@ipcc.ca')
          });
        }

        setLoading(false);
        setFormSuccess(true);
        setFirstName({ value: '', error: true, touched: false });
        setLastName({ value: '', error: true, touched: false });
        setPhone({ value: '', error: true, touched: false });
        setEmail({ value: '', error: true, touched: false });
        setCity({ value: '', error: true, touched: false });
        setProvince({ value: '', error: true, touched: false });
        setReferer([]);
        setFinancialInterests([]);
      } catch (err) {
        console.warn(err, 'error');
        setLoading(false);
        setFormSuccess(false);
      }
    } else {
      setValidated(false);
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {modalActive ? (
        <AriaModal titleText="Contact us form">
          <Modal>
            <CloseHandler
              aria-label="Close the modal"
              tabIndex={0}
              onClick={() => {
                setModalActive(!modalActive);
                setFormSuccess(false);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  setModalActive(!modalActive);
                  setFormSuccess(false);
                }
              }}
            >
              <Icon alt="" image={Close} />
            </CloseHandler>
            {!formSuccess ? (
              <ModalContent ref={myRef}>
                <Heading>{`Ready to work with ${teamName}?`}</Heading>
                <Text>
                  To get started, please complete this form and a member of our
                  team will be in touch with you within 24-48 hours.
                </Text>
                <Form>
                  <FormGroupFields>
                    <Field>
                      <HiddenLabel htmlFor="firstName">First Name</HiddenLabel>
                      <InputValidated
                        theme="contact"
                        name="First Name"
                        id="firstName"
                        placeholder="First Name"
                        type="text"
                        onBlur={() => {
                          setFirstName({ ...firstName, touched: true });
                        }}
                        onChange={setFirstName}
                        value={firstName}
                      />
                      {((firstName.value === '' && firstName.touched) ||
                        (validated === false && firstName.value === '')) && (
                        <ErrorText role="alert">
                          First name is required.
                        </ErrorText>
                      )}
                    </Field>
                    <Field>
                      <HiddenLabel htmlFor="lastName">Last Name</HiddenLabel>
                      <InputValidated
                        theme="contact"
                        name="Last Name"
                        id="lastName"
                        placeholder="Last Name"
                        type="text"
                        onBlur={() => {
                          setLastName({ ...lastName, touched: true });
                        }}
                        onChange={setLastName}
                        value={lastName}
                      />
                      {((lastName.value === '' && lastName.touched) ||
                        (validated === false && lastName.value === '')) && (
                        <ErrorText role="alert">
                          Last name is required.
                        </ErrorText>
                      )}
                    </Field>
                    <Field>
                      <HiddenLabel htmlFor="phoneNumber">
                        Phone Number
                      </HiddenLabel>
                      <InputValidated
                        theme="contact"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder="Phone Number"
                        type="phone"
                        onBlur={() => {
                          setPhone({ ...phone, touched: true });
                        }}
                        onChange={setPhone}
                        value={phone}
                      />
                      {((phone.value === '' && phone.touched) ||
                        (validated === false && phone.value === '')) && (
                        <ErrorText role="alert">
                          Phone number is required.
                        </ErrorText>
                      )}
                    </Field>
                    <Field>
                      <HiddenLabel htmlFor="emailAddress">
                        Email Address
                      </HiddenLabel>
                      <InputValidated
                        theme="contact"
                        name="Email Address"
                        id="emailAddress"
                        placeholder="Email Address"
                        type="email"
                        onBlur={() => {
                          setEmail({ ...email, touched: true });
                        }}
                        onChange={setEmail}
                        value={email}
                      />
                      {((email.value === '' && email.touched) ||
                        (validated === false && email.value === '')) && (
                        <ErrorText role="alert">Email is required.</ErrorText>
                      )}
                    </Field>
                    <Field>
                      <HiddenLabel htmlFor="city">City</HiddenLabel>
                      <InputValidated
                        theme="contact"
                        name="city"
                        id="city"
                        placeholder="City"
                        type="text"
                        onBlur={() => {
                          setCity({ ...city, touched: true });
                        }}
                        onChange={setCity}
                        value={city}
                      />
                      {((city.value === '' && city.touched) ||
                        (validated === false && city.value === '')) && (
                        <ErrorText role="alert">City is required.</ErrorText>
                      )}
                    </Field>
                    <Field>
                      <HiddenLabel htmlFor="province">Province</HiddenLabel>
                      <InputValidated
                        theme="contact"
                        name="province"
                        id="province"
                        placeholder="Province"
                        type="text"
                        onBlur={() => {
                          setProvince({ ...province, touched: true });
                        }}
                        onChange={setProvince}
                        value={province}
                      />
                      {((province.value === '' && province.touched) ||
                        (validated === false && province.value === '')) && (
                        <ErrorText role="alert">
                          Province is required.
                        </ErrorText>
                      )}
                    </Field>
                  </FormGroupFields>
                  <CheckMarkFields>
                    <CheckboxLabel
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          setProximityCheck(!proximityCheck);
                        }
                      }}
                      onChange={() => {
                        setProximityCheck(!proximityCheck);
                      }}
                      htmlFor="consult"
                    >
                      I would like a free consultation
                      <HiddenCheckbox
                        aria-hidden
                        tabIndex={-1}
                        onChange={() => {
                          setChecked(!checked);
                        }}
                        checked={checked}
                        id="consult"
                        name="consult"
                        type="checkbox"
                      />
                      <CheckBox>
                        <Check aria-hidden checked={checked}>
                          <Icon image={Checkmark} alt="" />
                        </Check>
                      </CheckBox>
                    </CheckboxLabel>
                  </CheckMarkFields>

                  <Divider />

                  <RadioBoxesFields>
                    <MiniHeader>How did you hear about us?</MiniHeader>
                    {sourceBoxes.map((item) => {
                      return (
                        <RadioWrapper>
                          <Radio
                            name={item.name}
                            id={item.id}
                            type="checkbox"
                            onChange={() => {
                              if (referer.includes(item.id)) {
                                setReferer(
                                  referer.filter(
                                    (uncheckedItem) => uncheckedItem !== item.id
                                  )
                                );
                              } else {
                                const copy = referer.slice();
                                copy.push(item.id);
                                setReferer(copy);
                              }
                            }}
                          />
                          <RadioLabel htmlFor={item.id}>
                            {item.label}
                          </RadioLabel>
                        </RadioWrapper>
                      );
                    })}
                  </RadioBoxesFields>
                  {referer.length === 0 && validated === false && (
                    <CheckboxErrorText role="alert">
                      Please select how you heard about us.
                    </CheckboxErrorText>
                  )}
                  <RadioBoxesFields>
                    <MiniHeader>I am interested in:</MiniHeader>
                    {interestBoxes.map((item) => (
                      <RadioWrapper>
                        <Radio
                          name={item.name}
                          id={item.id}
                          type="checkbox"
                          onChange={() => {
                            if (financialInterests.includes(item.id)) {
                              setFinancialInterests(
                                financialInterests.filter(
                                  (uncheckedItem) => uncheckedItem !== item.id
                                )
                              );
                            } else {
                              const copy = financialInterests.slice();
                              copy.push(item.id);
                              setFinancialInterests(copy);
                            }
                          }}
                        />
                        <RadioLabel htmlFor={item.id}>{item.label}</RadioLabel>
                      </RadioWrapper>
                    ))}
                  </RadioBoxesFields>
                  {financialInterests.length === 0 && validated === false && (
                    <CheckboxErrorText role="alert">
                      Please select what you're interested in learning more
                      about.
                    </CheckboxErrorText>
                  )}
                  <TextArea
                    id="contactFormComments"
                    placeholder="Your comments here..."
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                  />

                  {loading ? (
                    <LoadingContainer>
                      <ClipLoader
                        color={'#FFFFFF'}
                        loading={loading}
                        size={25}
                      />
                    </LoadingContainer>
                  ) : (
                    <Button
                      type="button"
                      theme="full"
                      handleKeyDown={() => {
                        submitForm();
                      }}
                      handleClick={() => {
                        submitForm();
                      }}
                      uniqueId="formSubmit"
                      text="I would like a free consultation"
                    />
                  )}
                </Form>
              </ModalContent>
            ) : (
              <ModalContent>
                <Heading>Success!</Heading>
                <Text>{`${teamName} will be in touch with you shortly.`}</Text>
              </ModalContent>
            )}
          </Modal>
        </AriaModal>
      ) : (
        false
      )}
    </>
  );
};

const Modal = styled.div`
  width: 50%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  display: block;
  margin: 0 auto;
  position: relative;
  @media screen and (max-width: 768px) {
    min-width: 100%;
  }
  @media screen and (min-width: 768px) {
    min-width: 500px;
  }
  @media screen and (min-width: 1024px) {
    min-width: 800px;
  }
`;

const ModalContent = styled.div`
  padding: 50px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 40px 0px;
  }
`;

const Heading = styled.h3`
  font-family: Poppins;
  margin-top: 0px;
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 135%;
  letter-spacing: -0.02em;
`;

const MiniHeader = styled.legend`
  font-family: 'Roboto Flex', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 165%;
`;

const Text = styled.p`
  font-family: 'Roboto Flex', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 165%;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-family: 'Roboto Flex', sans-serif;
  margin-bottom: 0px;
`;

const CheckboxErrorText = styled.div`
  color: red;
  font-family: 'Roboto Flex', sans-serif;
  margin-bottom: 0px;
  padding-left: 20px;
  padding-bottom: 20px;
`;

const CloseHandler = styled.div`
  position: absolute;
  right: 0;
  width: 40px;
  top: 10px;
  &:focus {
    outline: 2px dotted black;
  }
  &:hover {
    cursor: pointer;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 47%;
  padding-bottom: 20px;
`;

const HiddenLabel = styled.label`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

const TextArea = styled.textarea`
  height: 200px;
  width: 100%;
  margin-bottom: 30px;
  font-family: 'Roboto Flex', sans-serif;
  font-size: 16px;
  line-height: 28px;
  text-indent: 5px;
  background-color: #f5f8f9;
  border: unset;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */

    font-family: 'Roboto Flex', sans-serif;
    padding-left: 5px;
    font-size: 18px;
    color: #000000;
    opacity: 0.4;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-family: 'Roboto Flex', sans-serif;
    padding-left: 5px;
    font-size: 18px;
    color: #000000;
    opacity: 0.4;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    font-family: 'Roboto Flex', sans-serif;
    padding-left: 5px;
    font-size: 18px;
    color: #000000;
    opacity: 0.4;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-family: 'Roboto Flex', sans-serif;
    padding-left: 5px;
    font-size: 18px;
    color: #000000;
    opacity: 0.4;
  }
  &:focus {
    outline: 2px dotted #000000;
  }
`;

const FormGroupFields = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 200px;
  justify-content: space-between;
`;

const CheckMarkFields = styled.fieldset`
  min-height: 50px;
  border: unset;
  display: flex;
  padding-bottom: 0px;
  @media screen and (max-width: 768px) {
    padding: 20px 0px 5px;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  min-height: fit-content;
  padding-bottom: 18px;
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  font-family: 'Roboto Flex', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 115%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const RadioBoxesFields = styled.fieldset`
  display: flex;
  border: unset;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    padding: 0px;
    flex-direction: column;
  }
`;

const HiddenCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Divider = styled.hr`
  background-color: #f5f8f9;
  border: unset;
  height: 3px;
  width: 100%;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-family: 'Roboto Flex', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 35px;
  line-height: 115%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:focus {
    outline: 2px dotted #000000;
  }
`;

const RadioLabel = styled.label`
  font-family: 'Roboto Flex', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: -40px;

    /* Fixed alignment in some browsers. */
    top: -5px;

    background: #f5f8f9;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 25px;
    height: 25px;
  }

  &::after {
    content: '';
    position: absolute;
    left: -34.5px;
    top: 0px;
    width: 16px;
    height: 16px;
  }
  &:focus {
    outline: 2px dotted #000000;
  }
`;

const CheckBox = styled.span`
  position: absolute;
  left: -3px;
  height: 25px;
  width: 25px;
  background-color: #f5f8f9;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const Radio = styled.input`
  opacity: 0;
  &:checked {
    + ${RadioLabel}::after {
      width: 16px;
      background-image: url(${Checkmark});
      height: 16px;
      display: inline-block;
      background-size: 16px;
      background-repeat: no-repeat;
      content: "";
    }
  }
  &:focus {
    + ${RadioLabel}::before {
      outline: 2px dotted #000000;
    }
`;

const Check = styled.span<{ checked: boolean }>`
  position: absolute;
  width: 16px;
  top: 4.3px;
  left: 4.3px;
  visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
`;
const LoadingContainer = styled.div`
  width: 100%;
  height: 54px;
  background-color: #016432;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ContactModal;
