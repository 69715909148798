import React, { useEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { CoordinatePair } from 'models/coordinate';

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import ClipLoader from 'react-spinners/ClipLoader';
import Input from 'components/General/Input';
import Button from './Button';
import { ReactGA4Event } from 'utilities/reactGA4Event';

const SearchBar: React.FC<{
  setUserLocation: Dispatch<SetStateAction<CoordinatePair | undefined>>;
  collectSearchFieldAdvisors: (searchString: string) => void;
  collectAddressBasedAdvisors: () => void;
  loading: boolean;
  setMapboxSearchType: Dispatch<SetStateAction<string | undefined>>;
  searchType: string;
  setInitialSearchPerformed: Dispatch<SetStateAction<boolean>>;
}> = ({
  setUserLocation,
  collectSearchFieldAdvisors,
  collectAddressBasedAdvisors,
  loading,
  setMapboxSearchType,
  searchType,
  setInitialSearchPerformed
}) => {
  const [search, setSearch] = React.useState('');
  const Geocoder = new MapboxGeocoder({
    accessToken: `${process.env.REACT_APP_MAPBOX_API_KEY}`,
    types: 'region,place,postcode,address,neighborhood,locality',
    placeholder: 'Search by city or postal code',
    minLength: 0,
    countries: 'ca'
  });

  useEffect(() => {
    if (searchType === 'location') {
      Geocoder.addTo('#geocoder-container');
    }
  }, [searchType]);

  Geocoder.on('result', function (e) {
    if (e && e.result) {
      ReactGA4Event('event', 'search', {
        search_term: `${e.result.place_name}`,
        search_type: 'find_an_advisor',
        search_by: 'location'
      });
      setUserLocation({
        latitude: e.result.geometry.coordinates[1],
        longitude: e.result.geometry.coordinates[0]
      });
      if (e.result.place_type.includes('place')) {
        setMapboxSearchType('city');
      } else {
        setMapboxSearchType(undefined);
      }
      setSearch('');
    }
  });

  Geocoder.on('results', function (e) {
    if (e?.features[0]?.geometry?.coordinates[0]) {
      setUserLocation({
        latitude: e.features[0].geometry.coordinates[1],
        longitude: e.features[0].geometry.coordinates[0]
      });
    }
  });

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };

  function success(pos: any) {
    const crd = pos.coords;
    setUserLocation({
      latitude: crd.latitude,
      longitude: crd.longitude
    });
  }

  function error(err: any) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  const handleCombinedSearch = async () => {
    setInitialSearchPerformed(true);
    if (search && searchType === 'advisorName') {
      await navigator.geolocation.getCurrentPosition(success, error, options);
      collectSearchFieldAdvisors(search);
      ReactGA4Event('event', 'search', {
        search_term: `${search}`,
        search_type: 'find_an_advisor',
        search_by: 'advisor'
      });
    } else {
      collectAddressBasedAdvisors();
    }
  };

  return (
    <GeocoderContainer>
      {searchType === 'location' ? (
        <GeocoderRender
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              e.preventDefault();
              handleCombinedSearch();
            }
          }}
          id="geocoder-container"
        />
      ) : (
        <Input
          name="filter1"
          id="filter1"
          onChange={(e) => {
            setSearch(e);
          }}
          onKeyDown={() => handleCombinedSearch()}
          value={search}
          placeholder="Enter an Advisor name"
          type="text"
          theme="light"
        />
      )}
      {loading ? (
        <LoadingContainer>
          <ClipLoader color={'#FFFFFF'} loading={loading} size={25} />
        </LoadingContainer>
      ) : (
        <Button
          uniqueId="combinedSearch"
          type="button"
          text="Find"
          handleClick={() => {
            handleCombinedSearch();
          }}
        />
      )}
    </GeocoderContainer>
  );
};

const GeocoderContainer = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 120px;
  }
`;

const LoadingContainer = styled.div`
  width: 148px;
  height: 54px;
  background-color: #016432;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GeocoderRender = styled.div`
  .mapboxgl-ctrl-geocoder--pin-right {
    display: none;
  }

  .mapboxgl-ctrl-geocoder--suggestion:hover {
    cursor: pointer;
  }
  .mapboxgl-ctrl-geocoder {
    font-size: 18px;
    line-height: 24px;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    position: relative;
    background-color: #fff;
    width: 100%;
    min-width: 240px;
    z-index: 1;
    border-radius: 4px;
    transition: width 0.25s, min-width 0.25s;
    margin: 0 auto;
    box-shadow: unset !important;
    width: 100%;
    max-width: unset;

    .suggestions {
      width: 450px;
      font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
      background-color: #fff;
      border-radius: 4px;
      left: 0;
      list-style: none;
      margin: 0;
      padding: 6px 12px;
      position: absolute;
      top: 110%; /* fallback */
      top: calc(100% + 6px);
      z-index: 1000;
      overflow: hidden;
      font-size: 15px;
      top: unset;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .mapboxgl-ctrl-geocoder--suggestion {
        padding: 5px 0px;
      }
      .mapboxgl-ctrl-geocoder--suggestion-title {
        font-weight: bold;
      }
      .mapboxgl-ctrl-geocoder--suggestion-title,
      .mapboxgl-ctrl-geocoder--suggestion-address {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      > li {
        a {
          cursor: default;
          display: block;
          color: #404040;
          &:focus {
            outline: 2px dotted black;
          }
        }
      }
    }
    input {
      font: inherit;
      width: 100%;
      border: 0;
      background-color: transparent;
      margin: 0;
      height: 50px;
      padding: 6px 45px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 0 !important;
      background-color: #ffffff;
      border: 1px solid #d4e7ed;
      border-radius: unset;
      font-family: 'Roboto Flex', sans-serif;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      letter-spacing: 0.02em;
      text-align: left;
      height: 53px;
      color: #016432;
      text-indent: 10px;
      width: 470px;
      color: #000000;
      padding: 0px;
      @media screen and (max-width: 768px) {
        width: 280px;
        min-width: unset;
      }
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-family: 'Roboto Flex', sans-serif;
        padding-left: 5px;
        font-size: 18px;
        color: #000000;
        opacity: 0.4;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        font-family: 'Roboto Flex', sans-serif;
        padding-left: 5px;
        font-size: 18px;
        color: #000000;
        opacity: 0.4;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        font-family: 'Roboto Flex', sans-serif;
        padding-left: 5px;
        font-size: 18px;
        color: #000000;
        opacity: 0.4;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        font-family: 'Roboto Flex', sans-serif;
        padding-left: 5px;
        font-size: 18px;
        color: #000000;
        opacity: 0.4;
      }
      &:focus {
        outline: 2px dotted #000000;
      }
    }
    svg {
      display: none;
    }
  }

  #geocoder {
    position: relative;
  }
`;

export default SearchBar;
